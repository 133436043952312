

.dx-draggable-dragging > * {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 6px 8px rgba(0, 0, 0, 0.2);
}


.dx-list-item:hover {
    background-color: #212121 !important;
    color: #dedede;
}

.dx-list-item:active {
    background-color: #212121 !important;
    color: #dedede !important;
}

.dx-list-item:focus {
    background-color: #212121 !important;
    color: #dedede !important;
}



